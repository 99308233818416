import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse, Switch } from '@mui/material';

import {
  Features,
  FitBarConfig,
  ProductRecommendationsConfig,
  isNotNullOrUndefined
} from '../framework';
import {
  FormFieldWrapper,
  FormLabel,
  FormNumberInput,
  FormRow,
  FormSection,
  FormSectionTitle,
  FormSubsection,
  FormTextInput
} from './form-input';
import { Gap } from './spacer';

export type FeatureSet = 'widget' | 'portal' | 'all';

export interface FeatureEditorProps {
  features: Features;
  onChange: (features: Features) => unknown;
  featureSet?: FeatureSet;
  hideTitle?: boolean;
  externallyChanged?: Partial<Features>;
}

export function FeatureEditor({
  features,
  onChange,
  featureSet = 'all',
  hideTitle,
  externallyChanged
}: FeatureEditorProps) {
  const { t } = useTranslation();

  const updateProductRecommendations = useCallback(
    (field: keyof ProductRecommendationsConfig, value: boolean) => {
      onChange({
        ...features,
        productRecommendations: {
          enabled: features?.productRecommendations?.enabled ?? false,
          matchColor: features?.productRecommendations?.matchColor ?? true,
          matchGender: features?.productRecommendations?.matchGender ?? true,
          matchCustomLabel0:
            features?.productRecommendations?.matchCustomLabel0 ?? false,
          matchProductCategory:
            features?.productRecommendations?.matchProductCategory ?? false,
          onlyOutOfStock:
            features?.productRecommendations?.onlyOutOfStock ?? false,
          [field]: value
        }
      });
    },
    [features, onChange]
  );

  const updateFitBar = useCallback(
    (field: keyof FitBarConfig, value: boolean | number) => {
      onChange({
        ...features,
        fitBar: {
          enabled: features?.fitBar?.enabled ?? false,
          onlyWhenNotFilled: features?.fitBar?.onlyWhenNotFilled ?? false,
          hideIfZero: features?.fitBar?.hideIfZero ?? false,
          showIfUndefined: features?.fitBar?.showIfUndefined ?? false,
          sizeChartReturnsThreshold:
            features?.fitBar?.sizeChartReturnsThreshold ?? 0,
          customFitBarVisuals: features?.fitBar?.customFitBarVisuals ?? false,
          [field]: value
        }
      });
    },
    [features, onChange]
  );

  return (
    <>
      {(featureSet === 'widget' || featureSet === 'all') && (
        <>
          {!hideTitle && (
            <FormSectionTitle>{t('widgetFeatureConfig')}</FormSectionTitle>
          )}
          <FormRow
            highlighted={isNotNullOrUndefined(externallyChanged?.buttonHidden)}
          >
            <FormLabel>{t('buttonHidden')}</FormLabel>
            <FormFieldWrapper>
              <Switch
                name="buttonHidden"
                color="primary"
                onChange={(
                  event: React.ChangeEvent<{
                    checked: boolean;
                  }>
                ) =>
                  onChange({
                    ...features,
                    buttonHidden: event.target.checked
                  })
                }
                checked={!!features?.buttonHidden}
              />
            </FormFieldWrapper>
          </FormRow>
          <FormRow
            highlighted={isNotNullOrUndefined(
              externallyChanged?.skipLabelDetection
            )}
          >
            <FormLabel>{t('skipLabelDetection')}</FormLabel>
            <FormFieldWrapper>
              <Switch
                name="skipLabelDetection"
                color="primary"
                onChange={(
                  event: React.ChangeEvent<{
                    checked: boolean;
                  }>
                ) =>
                  onChange({
                    ...features,
                    skipLabelDetection: event.target.checked
                  })
                }
                checked={!!features?.skipLabelDetection}
              />
            </FormFieldWrapper>
          </FormRow>
          <FormRow
            highlighted={isNotNullOrUndefined(
              externallyChanged?.automaticTagPrediction
            )}
          >
            <FormLabel>{t('automaticTagPrediction')}</FormLabel>
            <FormFieldWrapper>
              <Switch
                name="automaticTagPrediction"
                color="primary"
                onChange={(
                  event: React.ChangeEvent<{
                    checked: boolean;
                  }>
                ) =>
                  onChange({
                    ...features,
                    automaticTagPrediction: event.target.checked
                  })
                }
                checked={!!features?.automaticTagPrediction}
              />
            </FormFieldWrapper>
          </FormRow>
          {features.automaticTagPrediction && (
            <FormRow
              highlighted={isNotNullOrUndefined(
                externallyChanged?.automaticTagOnlySizeUploads
              )}
            >
              <Gap />
              <FormLabel>{t('automaticTagOnlySizeUploads')}</FormLabel>
              <FormFieldWrapper>
                <Switch
                  name="automaticTagOnlySizeUploads"
                  color="primary"
                  onChange={(
                    event: React.ChangeEvent<{
                      checked: boolean;
                    }>
                  ) =>
                    onChange({
                      ...features,
                      automaticTagOnlySizeUploads: event.target.checked
                    })
                  }
                  checked={!!features?.automaticTagOnlySizeUploads}
                />
              </FormFieldWrapper>
            </FormRow>
          )}
          <FormRow
            highlighted={isNotNullOrUndefined(
              externallyChanged?.filledWidgetResult
            )}
          >
            <FormLabel>{t('filledWidgetResult')}</FormLabel>
            <FormFieldWrapper>
              <Switch
                name="filledWidgetResult"
                color="primary"
                onChange={(
                  event: React.ChangeEvent<{
                    checked: boolean;
                  }>
                ) =>
                  onChange({
                    ...features,
                    filledWidgetResult: event.target.checked
                  })
                }
                checked={!!features?.filledWidgetResult}
              />
            </FormFieldWrapper>
          </FormRow>
          <FormRow
            highlighted={isNotNullOrUndefined(
              externallyChanged?.dualWidgetResults
            )}
          >
            <FormLabel>{t('dualWidgetResults')}</FormLabel>
            <FormFieldWrapper>
              <Switch
                name="dualWidgetResults"
                color="primary"
                onChange={(
                  event: React.ChangeEvent<{
                    checked: boolean;
                  }>
                ) =>
                  onChange({
                    ...features,
                    dualWidgetResults: event.target.checked
                  })
                }
                checked={!!features?.dualWidgetResults}
              />
            </FormFieldWrapper>
          </FormRow>
          <FormRow
            highlighted={isNotNullOrUndefined(
              externallyChanged?.expandedWidgetResults
            )}
          >
            <FormLabel>{t('expandedWidgetResults')}</FormLabel>
            <FormFieldWrapper>
              <Switch
                name="expandedWidgetResults"
                color="primary"
                onChange={(
                  event: React.ChangeEvent<{
                    checked: boolean;
                  }>
                ) =>
                  onChange({
                    ...features,
                    expandedWidgetResults: event.target.checked
                  })
                }
                checked={!!features?.expandedWidgetResults}
              />
            </FormFieldWrapper>
          </FormRow>
          <FormRow
            highlighted={isNotNullOrUndefined(
              externallyChanged?.widgetUserName
            )}
          >
            <FormLabel>{t('widgetUserName')}</FormLabel>
            <FormFieldWrapper>
              <Switch
                name="widgetUserName"
                color="primary"
                onChange={(
                  event: React.ChangeEvent<{
                    checked: boolean;
                  }>
                ) =>
                  onChange({
                    ...features,
                    widgetUserName: event.target.checked
                  })
                }
                checked={!!features?.widgetUserName}
              />
            </FormFieldWrapper>
          </FormRow>
          <FormRow
            highlighted={isNotNullOrUndefined(
              externallyChanged?.mobileHeightAndWeightInputField
            )}
          >
            <FormLabel>{t('mobileHeightAndWeightInputField')}</FormLabel>
            <FormFieldWrapper>
              <Switch
                name="mobileHeightAndWeightInputField"
                color="primary"
                onChange={(
                  event: React.ChangeEvent<{
                    checked: boolean;
                  }>
                ) =>
                  onChange({
                    ...features,
                    mobileHeightAndWeightInputField: event.target.checked
                  })
                }
                checked={!!features?.mobileHeightAndWeightInputField}
              />
            </FormFieldWrapper>
          </FormRow>
          <FormRow
            highlighted={isNotNullOrUndefined(
              externallyChanged?.mobileHeightAndWeightInputField
            )}
          >
            <FormLabel>{t('mobileHeightAndWeightSlotMachinePicker')}</FormLabel>
            <FormFieldWrapper>
              <Switch
                name="mobileHeightAndWeightSlotMachinePicker"
                color="primary"
                onChange={(
                  event: React.ChangeEvent<{
                    checked: boolean;
                  }>
                ) =>
                  onChange({
                    ...features,
                    mobileHeightAndWeightSlotMachinePicker: event.target.checked
                  })
                }
                checked={!!features?.mobileHeightAndWeightSlotMachinePicker}
              />
            </FormFieldWrapper>
          </FormRow>
          <FormRow
            highlighted={isNotNullOrUndefined(externallyChanged?.ageAsDropdown)}
          >
            <FormLabel>{t('ageAsDropdown')}</FormLabel>
            <FormFieldWrapper>
              <Switch
                name="ageAsDropdown"
                color="primary"
                onChange={(
                  event: React.ChangeEvent<{
                    checked: boolean;
                  }>
                ) =>
                  onChange({
                    ...features,
                    ageAsDropdown: event.target.checked
                  })
                }
                checked={!!(features?.ageAsDropdown ?? true)}
              />
            </FormFieldWrapper>
          </FormRow>
          <FormRow
            highlighted={isNotNullOrUndefined(
              externallyChanged?.customerFeedback
            )}
          >
            <FormLabel>{t('customerFeedback')}</FormLabel>
            <FormFieldWrapper>
              <Switch
                name="customerFeedback"
                color="primary"
                onChange={(
                  event: React.ChangeEvent<{
                    checked: boolean;
                  }>
                ) =>
                  onChange({
                    ...features,
                    customerFeedback: event.target.checked
                  })
                }
                checked={!!features?.customerFeedback}
              />
            </FormFieldWrapper>
          </FormRow>
          <FormRow
            highlighted={isNotNullOrUndefined(
              externallyChanged?.braSizeInsteadOfChest
            )}
          >
            <FormLabel>{t('braSizeInsteadOfChest')}</FormLabel>
            <FormFieldWrapper>
              <Switch
                name="braSizeInsteadOfChest"
                color="primary"
                onChange={(
                  event: React.ChangeEvent<{
                    checked: boolean;
                  }>
                ) =>
                  onChange({
                    ...features,
                    braSizeInsteadOfChest: event.target.checked
                  })
                }
                checked={!!features?.braSizeInsteadOfChest}
              />
            </FormFieldWrapper>
          </FormRow>
          <FormRow
            highlighted={isNotNullOrUndefined(
              externallyChanged?.orderBasedSizing
            )}
          >
            <FormLabel>{t('orderBasedSizing')}</FormLabel>
            <FormFieldWrapper>
              <Switch
                name="orderBasedSizing"
                color="primary"
                onChange={(
                  event: React.ChangeEvent<{
                    checked: boolean;
                  }>
                ) =>
                  onChange({
                    ...features,
                    orderBasedSizing: event.target.checked
                  })
                }
                checked={!!features?.orderBasedSizing}
              />
            </FormFieldWrapper>
          </FormRow>
          <FormRow
            highlighted={isNotNullOrUndefined(
              externallyChanged?.orderBasedSizing
            )}
          >
            <FormLabel>{t('uiRefresh2024')}</FormLabel>
            <FormFieldWrapper>
              <Switch
                name="uiRefresh2024"
                color="primary"
                onChange={(
                  event: React.ChangeEvent<{
                    checked: boolean;
                  }>
                ) =>
                  onChange({
                    ...features,
                    uiRefresh2024: event.target.checked
                  })
                }
                checked={!!features?.uiRefresh2024}
              />
            </FormFieldWrapper>
          </FormRow>
          <FormSubsection hideBorder={!features?.fitBar?.enabled}>
            <FormRow
              highlighted={isNotNullOrUndefined(
                externallyChanged?.fitBar?.enabled
              )}
            >
              <FormLabel>{t('fitBar')}</FormLabel>
              <FormFieldWrapper>
                <Switch
                  name="fitBar"
                  color="primary"
                  onChange={(
                    event: React.ChangeEvent<{
                      checked: boolean;
                    }>
                  ) => updateFitBar('enabled', event.target.checked)}
                  checked={!!features?.fitBar?.enabled}
                />
              </FormFieldWrapper>
            </FormRow>
            <Collapse in={features?.fitBar?.enabled}>
              <FormSection>
                <FormRow
                  highlighted={isNotNullOrUndefined(
                    externallyChanged?.fitBar?.sizeChartReturnsThreshold
                  )}
                >
                  <FormLabel>{t('sizeChartReturnsThreshold')}</FormLabel>
                  <FormFieldWrapper>
                    <FormNumberInput
                      value={features?.fitBar?.sizeChartReturnsThreshold ?? 50}
                      onChange={(value: number) =>
                        updateFitBar('sizeChartReturnsThreshold', value)
                      }
                    />
                  </FormFieldWrapper>
                </FormRow>
                <FormRow
                  highlighted={isNotNullOrUndefined(
                    externallyChanged?.fitBar?.onlyWhenNotFilled
                  )}
                >
                  <FormLabel>{t('fitBarOnlyWhenNotFilled')}</FormLabel>
                  <FormFieldWrapper>
                    <Switch
                      name="fitBarOnlyWhenNotFilled"
                      color="primary"
                      onChange={(
                        event: React.ChangeEvent<{
                          checked: boolean;
                        }>
                      ) =>
                        updateFitBar('onlyWhenNotFilled', event.target.checked)
                      }
                      checked={!!features?.fitBar?.onlyWhenNotFilled}
                    />
                  </FormFieldWrapper>
                </FormRow>
                <FormRow
                  highlighted={isNotNullOrUndefined(
                    externallyChanged?.fitBar?.hideIfZero
                  )}
                >
                  <FormLabel>{t('fitBarHideIfZero')}</FormLabel>
                  <FormFieldWrapper>
                    <Switch
                      name="fitBarHideIfZero"
                      color="primary"
                      onChange={(
                        event: React.ChangeEvent<{
                          checked: boolean;
                        }>
                      ) => updateFitBar('hideIfZero', event.target.checked)}
                      checked={!!features?.fitBar?.hideIfZero}
                    />
                  </FormFieldWrapper>
                </FormRow>
                <FormRow
                  highlighted={isNotNullOrUndefined(
                    externallyChanged?.fitBar?.showIfUndefined
                  )}
                >
                  <FormLabel>{t('fitBarShowIfUndefined')}</FormLabel>
                  <FormFieldWrapper>
                    <Switch
                      name="fitBarShowIfUndefined"
                      color="primary"
                      onChange={(
                        event: React.ChangeEvent<{
                          checked: boolean;
                        }>
                      ) =>
                        updateFitBar('showIfUndefined', event.target.checked)
                      }
                      checked={!!features?.fitBar?.showIfUndefined}
                    />
                  </FormFieldWrapper>
                </FormRow>
                <FormRow
                  highlighted={isNotNullOrUndefined(
                    externallyChanged?.fitBar?.customFitBarVisuals
                  )}
                >
                  <FormLabel>{t('customFitBarVisuals')}</FormLabel>
                  <FormFieldWrapper>
                    <Switch
                      name="customFitBarVisuals"
                      color="primary"
                      onChange={(
                        event: React.ChangeEvent<{
                          checked: boolean;
                        }>
                      ) =>
                        updateFitBar(
                          'customFitBarVisuals',
                          event.target.checked
                        )
                      }
                      checked={!!features?.fitBar?.customFitBarVisuals}
                    />
                  </FormFieldWrapper>
                </FormRow>
              </FormSection>
            </Collapse>
          </FormSubsection>
          <FormSubsection
            hideBorder={!features?.productRecommendations?.enabled}
          >
            <FormRow
              highlighted={isNotNullOrUndefined(
                externallyChanged?.productRecommendations?.enabled
              )}
            >
              <FormLabel>{t('productRecommendations')}</FormLabel>
              <FormFieldWrapper>
                <Switch
                  name="productRecommendations"
                  color="primary"
                  onChange={(
                    event: React.ChangeEvent<{
                      checked: boolean;
                    }>
                  ) =>
                    updateProductRecommendations(
                      'enabled',
                      event.target.checked
                    )
                  }
                  checked={!!features?.productRecommendations?.enabled}
                />
              </FormFieldWrapper>
            </FormRow>
            <Collapse in={features?.productRecommendations?.enabled}>
              <FormSection>
                <FormRow
                  highlighted={isNotNullOrUndefined(
                    externallyChanged?.productRecommendations?.onlyOutOfStock
                  )}
                >
                  <FormLabel>
                    {t('productRecommendationsOnlyOutOfStock')}
                  </FormLabel>
                  <FormFieldWrapper>
                    <Switch
                      name="productRecommendationsOnlyOutOfStock"
                      color="primary"
                      onChange={(
                        event: React.ChangeEvent<{
                          checked: boolean;
                        }>
                      ) =>
                        updateProductRecommendations(
                          'onlyOutOfStock',
                          event.target.checked
                        )
                      }
                      checked={
                        !!features?.productRecommendations?.onlyOutOfStock
                      }
                    />
                  </FormFieldWrapper>
                </FormRow>
                <FormRow
                  highlighted={isNotNullOrUndefined(
                    externallyChanged?.productRecommendations?.matchColor
                  )}
                >
                  <FormLabel>{t('productRecommendationsMatchColor')}</FormLabel>
                  <FormFieldWrapper>
                    <Switch
                      name="productRecommendationsMatchColor"
                      color="primary"
                      onChange={(
                        event: React.ChangeEvent<{
                          checked: boolean;
                        }>
                      ) =>
                        updateProductRecommendations(
                          'matchColor',
                          event.target.checked
                        )
                      }
                      checked={!!features?.productRecommendations?.matchColor}
                    />
                  </FormFieldWrapper>
                </FormRow>
                <FormRow
                  highlighted={isNotNullOrUndefined(
                    externallyChanged?.productRecommendations?.matchGender
                  )}
                >
                  <FormLabel>
                    {t('productRecommendationsMatchGender')}
                  </FormLabel>
                  <FormFieldWrapper>
                    <Switch
                      name="productRecommendationsMatchGender"
                      color="primary"
                      onChange={(
                        event: React.ChangeEvent<{
                          checked: boolean;
                        }>
                      ) =>
                        updateProductRecommendations(
                          'matchGender',
                          event.target.checked
                        )
                      }
                      checked={!!features?.productRecommendations?.matchGender}
                    />
                  </FormFieldWrapper>
                </FormRow>
                <FormRow
                  highlighted={isNotNullOrUndefined(
                    externallyChanged?.productRecommendations
                      ?.matchProductCategory
                  )}
                >
                  <FormLabel>
                    {t('productRecommendationsMatchProductCategory')}
                  </FormLabel>
                  <FormFieldWrapper>
                    <Switch
                      name="productRecommendationsMatchProductCategory"
                      color="primary"
                      onChange={(
                        event: React.ChangeEvent<{
                          checked: boolean;
                        }>
                      ) =>
                        updateProductRecommendations(
                          'matchProductCategory',
                          event.target.checked
                        )
                      }
                      checked={
                        !!features?.productRecommendations?.matchProductCategory
                      }
                    />
                  </FormFieldWrapper>
                </FormRow>
                <FormRow
                  highlighted={isNotNullOrUndefined(
                    externallyChanged?.productRecommendations?.matchCustomLabel0
                  )}
                >
                  <FormLabel>
                    {t('productRecommendationsMatchCustomLabel0')}
                  </FormLabel>
                  <FormFieldWrapper>
                    <Switch
                      name="productRecommendationsMatchCustomLabel0"
                      color="primary"
                      onChange={(
                        event: React.ChangeEvent<{
                          checked: boolean;
                        }>
                      ) =>
                        updateProductRecommendations(
                          'matchCustomLabel0',
                          event.target.checked
                        )
                      }
                      checked={
                        !!features?.productRecommendations?.matchCustomLabel0
                      }
                    />
                  </FormFieldWrapper>
                </FormRow>
              </FormSection>
            </Collapse>
          </FormSubsection>
        </>
      )}
      {(featureSet === 'portal' || featureSet === 'all') && (
        <>
          {!hideTitle && (
            <FormSectionTitle>{t('portalFeatureConfig')}</FormSectionTitle>
          )}
          <FormRow
            highlighted={isNotNullOrUndefined(externallyChanged?.widgetTester)}
          >
            <FormLabel>{t('widgetTester')}</FormLabel>
            <FormFieldWrapper>
              <Switch
                name="widgetTester"
                color="primary"
                onChange={(
                  event: React.ChangeEvent<{
                    checked: boolean;
                  }>
                ) =>
                  onChange({
                    ...features,
                    widgetTester: event.target.checked
                  })
                }
                checked={features?.widgetTester ?? true}
              />
            </FormFieldWrapper>
          </FormRow>
          <FormRow
            highlighted={isNotNullOrUndefined(externallyChanged?.effects)}
          >
            <FormLabel>{t('effects')}</FormLabel>
            <FormFieldWrapper>
              <Switch
                name="effectsEnabled"
                color="primary"
                onChange={(
                  event: React.ChangeEvent<{
                    checked: boolean;
                  }>
                ) =>
                  onChange({
                    ...features,
                    effects: event.target.checked
                  })
                }
                checked={features?.effects ?? true}
              />
            </FormFieldWrapper>
          </FormRow>
          <FormRow
            highlighted={isNotNullOrUndefined(externallyChanged?.integrations)}
          >
            <FormLabel>{t('integrations')}</FormLabel>
            <FormFieldWrapper>
              <Switch
                name="integrationsEnabled"
                color="primary"
                onChange={(
                  event: React.ChangeEvent<{
                    checked: boolean;
                  }>
                ) =>
                  onChange({
                    ...features,
                    integrations: event.target.checked
                  })
                }
                checked={features?.integrations ?? false}
              />
            </FormFieldWrapper>
          </FormRow>
          <FormRow
            highlighted={isNotNullOrUndefined(externallyChanged?.returns)}
          >
            <FormLabel>{t('returnInsights')}</FormLabel>
            <FormFieldWrapper>
              <Switch
                name="returnsEnabled"
                color="primary"
                onChange={(
                  event: React.ChangeEvent<{
                    checked: boolean;
                  }>
                ) =>
                  onChange({
                    ...features,
                    returns: event.target.checked
                  })
                }
                checked={features?.returns ?? false}
              />
            </FormFieldWrapper>
          </FormRow>
          <FormRow
            highlighted={isNotNullOrUndefined(
              externallyChanged?.profileInsights
            )}
          >
            <FormLabel>{t('profileInsights')}</FormLabel>
            <FormFieldWrapper>
              <Switch
                name="profileInsightsEnabled"
                color="primary"
                onChange={(
                  event: React.ChangeEvent<{
                    checked: boolean;
                  }>
                ) =>
                  onChange({
                    ...features,
                    profileInsights: event.target.checked
                  })
                }
                checked={features?.profileInsights ?? false}
              />
            </FormFieldWrapper>
          </FormRow>
          <FormRow
            highlighted={isNotNullOrUndefined(
              externallyChanged?.productInsights
            )}
          >
            <FormLabel>{t('productInsights')}</FormLabel>
            <FormFieldWrapper>
              <Switch
                name="productInsightsEnabled"
                color="primary"
                onChange={(
                  event: React.ChangeEvent<{
                    checked: boolean;
                  }>
                ) =>
                  onChange({
                    ...features,
                    productInsights: event.target.checked
                  })
                }
                checked={features?.productInsights ?? false}
              />
            </FormFieldWrapper>
          </FormRow>
          <FormRow
            highlighted={isNotNullOrUndefined(
              externallyChanged?.missedSalesInsights
            )}
          >
            <FormLabel>{t('missedSalesInsights')}</FormLabel>
            <FormFieldWrapper>
              <Switch
                name="missedSalesInsightsEnabled"
                color="primary"
                onChange={(
                  event: React.ChangeEvent<{
                    checked: boolean;
                  }>
                ) =>
                  onChange({
                    ...features,
                    missedSalesInsights: event.target.checked
                  })
                }
                checked={features?.missedSalesInsights ?? false}
              />
            </FormFieldWrapper>
          </FormRow>
          <FormRow
            highlighted={isNotNullOrUndefined(
              externallyChanged?.productsScreen
            )}
          >
            <FormLabel>{t('productsScreen')}</FormLabel>
            <FormFieldWrapper>
              <Switch
                name="productScreenEnabled"
                color="primary"
                onChange={(
                  event: React.ChangeEvent<{
                    checked: boolean;
                  }>
                ) =>
                  onChange({
                    ...features,
                    productsScreen: event.target.checked
                  })
                }
                checked={features?.productsScreen ?? false}
              />
            </FormFieldWrapper>
          </FormRow>
          <FormRow
            highlighted={isNotNullOrUndefined(
              externallyChanged?.ttsEditorInProductsScreen
            )}
          >
            <FormLabel>{t('ttsEditorInProductsScreen')}</FormLabel>
            <FormFieldWrapper>
              <Switch
                name="ttsEditorInProductsScreenEnabled"
                color="primary"
                onChange={(
                  event: React.ChangeEvent<{
                    checked: boolean;
                  }>
                ) =>
                  onChange({
                    ...features,
                    ttsEditorInProductsScreen: event.target.checked
                  })
                }
                checked={features?.ttsEditorInProductsScreen ?? false}
              />
            </FormFieldWrapper>
          </FormRow>
          <FormRow
            highlighted={isNotNullOrUndefined(
              externallyChanged?.feedOnlyProductsInProductScreen
            )}
          >
            <FormLabel>{t('feedOnlyProductsInProductScreen')}</FormLabel>
            <FormFieldWrapper>
              <Switch
                name="feedOnlyProductsInProductScreenEnabled"
                color="primary"
                onChange={(
                  event: React.ChangeEvent<{
                    checked: boolean;
                  }>
                ) =>
                  onChange({
                    ...features,
                    feedOnlyProductsInProductScreen: event.target.checked
                  })
                }
                checked={features?.feedOnlyProductsInProductScreen ?? false}
              />
            </FormFieldWrapper>
          </FormRow>
          <FormRow
            highlighted={isNotNullOrUndefined(externallyChanged?.roiTile)}
          >
            <FormLabel>{t('roiTile')}</FormLabel>
            <FormFieldWrapper>
              <Switch
                name="roiTileEnabled"
                color="primary"
                onChange={(
                  event: React.ChangeEvent<{
                    checked: boolean;
                  }>
                ) =>
                  onChange({
                    ...features,
                    roiTile: event.target.checked
                  })
                }
                checked={features?.roiTile ?? false}
              />
            </FormFieldWrapper>
          </FormRow>
          <FormRow
            highlighted={isNotNullOrUndefined(
              externallyChanged?.excludeGlobalReferenceBrands
            )}
          >
            <FormLabel>{t('excludeGlobalReferenceBrands')}</FormLabel>
            <FormFieldWrapper>
              <Switch
                name="excludeGlobalReferenceBrandsEnabled"
                color="primary"
                onChange={(
                  event: React.ChangeEvent<{
                    checked: boolean;
                  }>
                ) =>
                  onChange({
                    ...features,
                    excludeGlobalReferenceBrands: event.target.checked
                  })
                }
                checked={features?.excludeGlobalReferenceBrands ?? false}
              />
            </FormFieldWrapper>
          </FormRow>
          <FormRow
            highlighted={isNotNullOrUndefined(
              externallyChanged?.repeatReturners
            )}
          >
            <FormLabel>{t('repeatReturners')}</FormLabel>
            <FormFieldWrapper>
              <Switch
                name="repeatReturnersEnabled"
                color="primary"
                onChange={(
                  event: React.ChangeEvent<{
                    checked: boolean;
                  }>
                ) =>
                  onChange({
                    ...features,
                    repeatReturners: event.target.checked
                  })
                }
                checked={features?.repeatReturners ?? false}
              />
            </FormFieldWrapper>
          </FormRow>
          <FormRow
            highlighted={isNotNullOrUndefined(
              externallyChanged?.localizationEditor
            )}
          >
            <FormLabel>{t('localizationEditor')}</FormLabel>
            <FormFieldWrapper>
              <Switch
                name="localizationEditorEnabled"
                color="primary"
                onChange={(
                  event: React.ChangeEvent<{
                    checked: boolean;
                  }>
                ) =>
                  onChange({
                    ...features,
                    localizationEditor: event.target.checked
                  })
                }
                checked={features?.localizationEditor ?? false}
              />
            </FormFieldWrapper>
          </FormRow>
          <FormRow
            highlighted={isNotNullOrUndefined(
              externallyChanged?.localizationEditor
            )}
          >
            <FormLabel>{t('languageChooser')}</FormLabel>
            <FormFieldWrapper>
              <Switch
                name="languageChooserEnabled"
                color="primary"
                onChange={(
                  event: React.ChangeEvent<{
                    checked: boolean;
                  }>
                ) =>
                  onChange({
                    ...features,
                    languageChoice: event.target.checked
                  })
                }
                checked={features?.languageChoice ?? false}
              />
            </FormFieldWrapper>
          </FormRow>
          <FormRow
            highlighted={isNotNullOrUndefined(
              externallyChanged?.dashboardSegmentation
            )}
          >
            <FormLabel>{t('dashboardSegmentation')}</FormLabel>
            <FormFieldWrapper>
              <Switch
                name="dashboardSegmentationEnabled"
                color="primary"
                onChange={(
                  event: React.ChangeEvent<{
                    checked: boolean;
                  }>
                ) =>
                  onChange({
                    ...features,
                    dashboardSegmentation: event.target.checked
                  })
                }
                checked={features?.dashboardSegmentation ?? false}
              />
            </FormFieldWrapper>
          </FormRow>
          <FormSectionTitle>{t('aovFeatureConfig')}</FormSectionTitle>
          <FormRow
            highlighted={isNotNullOrUndefined(externallyChanged?.aovDashboard)}
          >
            <FormLabel>{t('aov')}</FormLabel>
            <FormFieldWrapper>
              <Switch
                name="aovDashboard"
                color="primary"
                onChange={(
                  event: React.ChangeEvent<{
                    checked: boolean;
                  }>
                ) =>
                  onChange({
                    ...features,
                    aovDashboard: {
                      enabled: event.target.checked,
                      currencySymbol:
                        features?.aovDashboard?.currencySymbol ?? '€',
                      currencyMultiplier:
                        features?.aovDashboard?.currencyMultiplier ?? 1
                    }
                  })
                }
                checked={features?.aovDashboard?.enabled}
              />
            </FormFieldWrapper>
          </FormRow>
          <FormRow>
            <FormLabel>{t('currencySymbol')}</FormLabel>
            <FormFieldWrapper>
              <FormTextInput
                value={features?.aovDashboard?.currencySymbol ?? '€'}
                onChange={(value: string) =>
                  onChange({
                    ...features,
                    aovDashboard: {
                      enabled: !!features?.aovDashboard?.enabled,
                      currencySymbol: value,
                      currencyMultiplier:
                        features?.aovDashboard?.currencyMultiplier ?? 1
                    }
                  })
                }
              />
            </FormFieldWrapper>
          </FormRow>
          <FormRow>
            <FormLabel>{t('currencyMultiplier')}</FormLabel>
            <FormFieldWrapper>
              <FormNumberInput
                value={features?.aovDashboard?.currencyMultiplier ?? 1}
                onChange={(value: number) =>
                  onChange({
                    ...features,
                    aovDashboard: {
                      enabled: !!features?.aovDashboard?.enabled,
                      currencySymbol:
                        features?.aovDashboard?.currencySymbol ?? '€',
                      currencyMultiplier: value
                    }
                  })
                }
              />
            </FormFieldWrapper>
          </FormRow>
        </>
      )}
    </>
  );
}
