import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { Spot, Error as SpotError } from 'spot-store';
import { redirectToLogin } from './use-lwl';
import { getApi } from './get-api';

export interface RetailerItem {
  id: number;
  name: string;
  slug: string;
}

export enum PlatformTypesEnum {
  Unknown = 'unknown',
  LightSpeed = 'lightspeed',
  Shopify = 'shopify',
  InstantCommerce = 'instant-commerce',
  Woocommerce = 'woocommerce',
  NextChapter = 'nextchapter',
  Shopware = 'shopware'
}

// eslint-disable-next-line
export type Platform = `${PlatformTypesEnum}`;

export interface User {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  avatar?: string | null;
  avatarS3?: string | null;
  optOutOfEmails?: boolean;
  phoneNumber?: string;
  lastLogin?: Date;
}

export interface Billing {
  taxNumber?: string;
  companyName?: string;
}

export type ExtendedUser = User & {
  password: string;
  retailers: RetailerItem[];
};

export interface SizeSpec {
  sizeLabel: string;
  useDefaults?: boolean;
  waist?: number;
  hips?: number;
  chest?: number;
  waistStretched?: number;
  hipsStretched?: number;
  chestStretched?: number;
  innerLeg?: number;
  shoulder?: number;
  sleeve?: number;
  thigh?: number;
  length?: number;
}

export interface SizeChartsUpload {
  productName?: string;
  productUrl?: string;
  gender?: string;
  sizeChartFile?: string;
  sizeChartFileUrl?: string;
}

export type SizeChartsUploadInfo = SizeChartsUpload & {
  id: number;
  retailer: string;
  brand: string;
  uploadedAt: string;
  uploadedBy?: {
    firstName?: string;
    lastName?: string;
    email: string;
  };
  tag?: string;
  productId: string;
  sizeChartSpecifications: SizeSpec[];
};

export type SizeChartsUploadItem = SizeChartsUpload & {
  sizeChartFileObj?: File;
};

export interface Size {
  id: number;
  label: string;
  value: number;
  maxValue: number | null;
  aliases: string[];
  displayName?: string;
}

export interface ItemAdjustment {
  id: number;
  adjustmentType: string;
  reason: string;
  createdAt: string;
  updatedAt: string;
}

export interface Item {
  id: number;
  brandName: string;
  gender: string;
  limit: number;
  measurementName: string;
  productName: string;
  sizes: Size[];
  ttsSkew: number;
  adjustments?: ItemAdjustment[];
}

export type Retailer = RetailerItem & {
  logo: string | null;
  logoS3: string | null;
  brands: Brand[];
  platform?: PlatformTypesEnum;
  url?: string;
  features?: Features;
  orderIdPrefix?: string;
  productFeed?: string;
  feedProductIdKey?: string;
  feedEncoding?: string;
  feedFormat?: 'xml' | 'csv' | 'json';
  categories?: string[];
  sftpEnabled?: boolean;
  sftpConfigHost?: string;
  sftpConfigFilePath?: string;
  sftpConfigPort?: number;
  sftpConfigUsername?: string;
  sftpConfigPasswordSecretPath?: string;
  sftpConfigPrivateKeySecretPath?: string;
};

export interface Brand {
  id: number;
  name: string;
  slug: string;
  logo?: string | null;
  logoS3?: string | null;
  retailers: RetailerItem[];
}

export interface BrandAlias {
  id: number;
  name: string;
  brandId: number;
}
export interface UntaggedProduct {
  prodId: string;
  product: string;
  brand_id: string;
  shopId: string;
  variants: string;
  pageUrl: string;
  tag?: string;
  gender?: string;
  available?: boolean;
}

export interface UntaggedProductSummary {
  products: string;
  shopId: string;
}

export interface BillingDetails {
  price?: number;
  discount?: number;
  name?: string;
  planId?: string;
  bundleFasletUsers?: number;
  cappedAmount?: number;
  trialDays?: number;
  fasletUserPrice?: number;
}

export type KnownIntegrations =
  | 'sendcloud'
  | 'returnista'
  | 'faslet'
  | 'returnless'
  | 'buckles'
  | 'datafuse';

export interface IntegrationDetails {
  sendcloud?: {
    apiKey?: string;
    apiSecret?: string;
  };
  returnless?: {
    apiUser?: string;
    apiPassword?: string;
  };
  returnista?: {
    apiKey?: string;
    reasonMappings?: {
      returnista: number;
      faslet: number;
    }[];
  };
  datafuse?: {
    apiKey?: string;
    reasonMappings?: {
      datafuse: number;
      faslet: number;
    }[];
  };
  fasletApi?: {
    apiToken?: string;
  };
  buckles?: {
    fasletApiToken?: string;
  };
}

export type ExtendedBrand = Brand & {
  baseUrl?: string | null;
  aliases: string[];
};

export interface Measurement {
  id: string;
  name: string;
  typeId: string;
}

export interface Product {
  id: number;
  name: string;
  type?: Type;
  ageGroup?: string;
}

export type ExtendedProduct = Product;

export interface Type {
  id: number;
  name: string;
}

export interface Tag {
  id: number;
  name: string;
  gender: string;
  product?: Product;
}

export type ExtendedTag = Tag;

export interface Webhook {
  id: number;
  address: string;
  format: string;
  itemGroup: string;
  itemAction: string;
  updatedAt: string;
  createdAt: string;
}

export interface ExperimentVariant {
  id: number;
  name: string;
  tracker?: string;
  features: Partial<Features>;
  widgetConfiguration?: Partial<WidgetConfiguration>;
}

export interface Experiment {
  id: number;
  name: string;
  userPercentage: number;
  retailers: RetailerItem[];
  variants: ExperimentVariant[];
  active: boolean;
  hasResults: boolean;
  targetDevice: 'all' | 'mobile' | 'desktopTablet';
  targetNewDevice: 'all' | 'newDevice' | 'returningDevice';
}

export interface AovConfig {
  enabled: boolean;
  currencySymbol: string;
  currencyMultiplier?: number;
}

export interface ProductRecommendationsConfig {
  enabled: boolean;
  onlyOutOfStock: boolean;
  matchColor: boolean;
  matchGender: boolean;
  matchCustomLabel0: boolean;
  matchProductCategory: boolean;
}

export interface FitBarConfig {
  enabled: boolean;
  customFitBarVisuals: boolean;
  sizeChartReturnsThreshold: number;
  onlyWhenNotFilled: boolean;
  hideIfZero: boolean;
  showIfUndefined: boolean;
}

const boolFeatureDefinition = {
  widgetTester: undefined,
  effects: undefined,
  buttonHidden: undefined,
  integrations: undefined,
  returns: undefined,
  profileInsights: undefined,
  productInsights: undefined,
  missedSalesInsights: undefined,
  skipLabelDetection: undefined,
  automaticTagPrediction: undefined,
  filledWidgetResult: undefined,
  dualWidgetResults: undefined,
  expandedWidgetResults: undefined,
  widgetUserName: undefined,
  automaticTagOnlySizeUploads: undefined,
  ageAsDropdown: undefined,
  mobileHeightAndWeightInputField: undefined,
  mobileHeightAndWeightSlotMachinePicker: undefined,
  customerFeedback: undefined,
  productsScreen: undefined,
  ttsEditorInProductsScreen: undefined,
  braSizeInsteadOfChest: undefined,
  orderBasedSizing: undefined,
  productRecommendationsOnlyOutOfStock: undefined,
  feedOnlyProductsInProductScreen: undefined,
  roiTile: undefined,
  excludeGlobalReferenceBrands: undefined,
  repeatReturners: undefined,
  uiRefresh2024: undefined,
  localizationEditor: undefined,
  languageChoice: undefined,
  dashboardSegmentation: undefined
} as const;

// Derive the type from the object
type BoolFeatures = {
  [K in keyof typeof boolFeatureDefinition]?: boolean;
};

interface NonBoolFeatures {
  aovDashboard?: AovConfig;
  productRecommendations?: ProductRecommendationsConfig;
  fitBar?: FitBarConfig;
}

export type Features = BoolFeatures & NonBoolFeatures;

// Derive the keys from the object
export const boolFeatureKeys = Object.keys(
  boolFeatureDefinition
) as (keyof typeof boolFeatureDefinition)[];

export type Error = SpotError;

export type ExtendedRetailer = Retailer & {
  default_locale?: string;
  widgetConfiguration?: WidgetConfiguration;
};

export type extraSteps = 'age';

export interface Effects {
  resultConfetti?: boolean;
  flyToCart?: boolean;
  buttonShake?: boolean;
  snow?: boolean;
  pride?: boolean;
  soccerNl?: boolean;
}

export interface ButtonCallToAction {
  enabled: boolean;
  delay: number;
  showAfterPageLoads: number;
  showOnlyOnce: boolean;
  alignment: 'auto' | 'left' | 'right' | 'center';
}

export interface SizeImpactReminder {
  enabled: boolean;
  showWhenResultDiffers: boolean;
  showWhenCartDiffers: boolean;
  alignment: 'auto' | 'left' | 'right' | 'center';
  variantJs?: string;
  cartJs?: string;
}

export interface LocalizationOverrides {
  [k: string]: any;
}

export type SizingAlgorithm = 'gorgonzola' | 'cheddar';

export type Alignment = 'left' | 'right' | 'center';

export interface TagMetadata {
  name: string;
  tag: string;
  product: string;
  retailer: string;
  brand: string;
  retailerName: string;
  brandName: string;
  title?: string;
  productUrl?: string;
  gender?: string;
  productImage?: string;
  sizeLabels?: string[];
}

export interface WidgetConfiguration {
  colorPrimary?: string;
  font?: string;
  invertButton?: boolean;
  spacing?: string;
  buttonFontSize?: number;
  maleMinWeight?: number;
  maleMaxWeight?: number;
  femaleMinWeight?: number;
  femaleMaxWeight?: number;
  maleMinHeight?: number;
  maleMaxHeight?: number;
  femaleMinHeight?: number;
  femaleMaxHeight?: number;
  kidsMinAge?: number;
  kidsMaxAge?: number;
  extraSteps?: { top: extraSteps[]; bottom: extraSteps[]; full: extraSteps[] };
  borderRadius?: number;
  popupBorderRadius?: number;
  fontColor?: string;
  bodyFontColor?: string;
  effects?: Effects;
  localizationOverrides?: LocalizationOverrides;
  buttonClass?: string;
  buttonStylesheet?: string;
  fasletOutlink?: boolean;
  sizingAlgorithm?: SizingAlgorithm;
  buttonFillParent?: boolean;
  buttonAlignmentInParent?: Alignment;
  buttonParentSelector?: string;
  buttonless?: boolean;
  buttonContentAlignment?: Alignment;
  buttonHeight?: number;
  buttonCallToAction?: ButtonCallToAction;
  blockEventsFromHiddenWidget?: boolean;
  addToCartJS?: string;
  showButtonAndResult?: boolean;
  disableIcon?: boolean;
  customIcon?: string;
  disableAddToCart?: boolean;
  chatMode?: boolean;
  forceSingleBrand?: boolean;
  sizeImpactReminder?: SizeImpactReminder;
  disableBorder?: boolean;
  disableUiShadows?: boolean;
  disableBackgroundBlur?: boolean;
  colorSecondary?: string;
  headerImage?: string;
  headerFontColor?: string;
  locale?: string;
  buttonBackgroundColor?: string;
  addToCartButtonColor?: string;
  addToCartFontColor?: string;
  productImagePadding?: number;
  fontColorSecondary?: string;
  disableUnitSelector?: boolean;
  disableBackToTop?: boolean;
}

export interface Multiplier {
  id: number;
  measurement: string;
  profile: number;
  value: number;
  brand: {
    slug: string;
    name: string;
    logo?: string;
    id: number;
  };
}

export type SlackValueMode = 'absolute' | 'relative-to-target';

export interface SlackValue {
  id: number;
  measurement: string;
  positiveValue: number;
  negativeValue: number;
  brand?: Brand;
  product?: Product;
  mode: SlackValueMode;
}

export type ProductGender = 'male' | 'female' | 'unisex';

export type DictionaryEntryType = 'title' | 'label';

export type AgeGroup = 'kids' | 'adult';

export interface Profile {
  h: number;
  w: number;
  a: number;
  fit: number;
  chest: number;
  hips: number;
  belly: number;
  name: boolean;
  u: string;

  fw?: number;
  rsb?: string;
  rss?: string;
  rsm?: string;
  bb?: number;
  bc?: string;
  ka?: number;
  kh?: number;
  rs?: number;
}
export interface PredictorDictionary {
  productWords: { word: string; product: string; type?: DictionaryEntryType }[];
  kidsProductWords: {
    word: string;
    product: string;
    type?: DictionaryEntryType;
  }[];
  genderWords: {
    word: string;
    gender: ProductGender;
    type?: DictionaryEntryType;
  }[];
}

export interface MissedSale {
  productId: string;
  product: string;
  requestedLabel: string;
  requestedOption: string;
  reason: string;
  shopId: string;
  price: number | null;
  users: number;
  brand: string;
}

export interface Notification {
  id: number;
  level: 'info' | 'warn' | 'error';
  title: string;
  message: string;
  icon: string;
  action: 'screen' | 'link';
  actionInfo: string;
  read: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export interface AccuracyStat {
  correlationId: string;
  users: number;
  profiles: {
    a: number;
    h: number;
    w: number;
    belly: number;
    hips?: number;
    chest?: number;
    fit: number;
  }[];
  title: string;
  variant: string;
  results: string[];
  shopId: string;
  brand: string;
  userIds: string[];
  accuracy: number;
  userGenders: string[];
  productGender: string;
  sizingErrors: number;
  orders: number;
  returned: number;
  tooSmall: number;
  tooBig: number;
  wrongSize: number;
}

export interface GroupedAccuracyStat {
  id: string;
  users: number;
  accuracy: number;
  productType: string;
  gender: string;
  adviceCount: number;
  orderCount: number;
  brand: string;
  retailer: string;
  returns: number;
  tooBig: number;
  tooSmall: number;
  wrongSize: number;
  adviceAndSizeRelated: number;
  adviceAndTooBig: number;
  adviceAndTooSmall: number;
  adviceAndWrongSize: number;
  adviceAndReturned: number;
  items: {
    correlationId: string;
    users: number;
    title: string;
    variant: string;
    shopId: string;
    brand: string;
  }[];
}

export interface WidgetErrorGroup {
  profiles: Profile[];
  products: string[];
  userGenders: string[];
  errorMessages: string[];
  productIds: string[];
  goodProfileCount: number;
  badProfileCount: number;
  retailer: string;
  brand: string;
  gender: string;
  productType: string;
}

export interface ProductInfoListResponse {
  imageLink?: string;
  productIdentifier: string;
  globalIdentifier?: string;
  gender?: string;
  name: string;
  source: 'feed' | 'widget' | 'size-chart-api';
  updatedAt: string;
  productUrl?: string;
  brand?: string;
  brandSlug?: string;
  retailer?: string;
  retailerSlug?: string;
  tagName?: string;
  tagProductName?: string;
  tagGender?: string;
  sizeUploads: SizeChartsUploadInfo[];
  sizeLabels?: string[];
  ageGroup?: string;
  customLabel0?: string;
  productCategory?: string;
  ttsSkew: number;
  hasSizeChart: boolean;
  hasChartLabels: boolean;
}

export type ProductInfoResponse = ProductInfoListResponse & {
  chart: {
    items: {
      id: number;
      measurement: string;
    }[];
    ttsSkew: number;
  };
  variants: {
    available?: boolean;
    variantIdentifier?: string;
    globalIdentifier?: string;
    sizeLabel?: string;
    color?: string;
  }[];
  tagMetadatas: {
    id: number;
    brandSlug: string;
    brandName: string;
    tagName: string;
    name: string;
  }[];
};

export interface ReferenceBrand {
  id: number;
  retailer: string;
  brand: string;
  type: string;
  order: number;
}

export interface ReferenceModel {
  id: number;
  name: string;
  imageUrl: string;
  brand: string;
  product: string;
  order: number;
}

export interface AuditTrailEvent {
  method: string;
  baseUrl: string;
  path: string;
  body: unknown;
  query: unknown;
  params: unknown;
  createdAt: string;
  user: {
    firstName: string;
    lastName: string;
    email: string;
  };
}

export interface StepConfig {
  id: number;
  type: string;
  retailer?: string;
  steps: string[];
  experimentVariantId?: number;
  firstTime: boolean;
  ageGroup: string;
}

export interface Category {
  id: number;
  name: string;
}

export interface ProductReturn {
  brand: Brand;
  users: number;
  followedAdvice: number;
  hasUsedWidget: number;
  fasletReturns: number;
  fasletSizeRelatedReturns: number;
  orders: number;
  sizeRelatedReturns: number;
  returns: number;
  returnReasons: number[];
  tag: Tag;
  correlationId: string;
  title: string;
  imageLink: string;
  name: string;
  productIdentifier: string;
  tagName: string;
  tagGender: string;
  tagProductName: string;
}

export interface Coupon {
  code: string;
  expiryDate: string;
  maxRedemptions: number;
  redemptions: number;
}

export interface RedeemedCoupon {
  code: string;
  redemptionDate: string;
  issuer: string;
}

export interface ErrorProfile {
  errorMessages: string[];
  profiles: Profile[];
  height: number;
  weight: number;
  users: number;
  gender: string;
  products: {
    brand: string;
    retailer: string;
    productType: string;
    productGender: string;
    productId: string;
    count: number;
  }[];
}

export interface RepeatReturner {
  userId: string;
  shopId: string;
  brands: string[];
  orders: number;
  orderIds: string[];
  items: number;
  productIds: string[];
  products: string[];
  variants: string[];
  times: string[];
  returns: number;
  returned: boolean;
  reasons: number[];
  sizeRelated: boolean[];
  sizeRelatedReturns: number;
  returnRate: number;
  followedAdvice: number;
  followedAdviceRate: number;
  hasUsedWidget: number;
  hasUsedWidgetRate: number;
  results: string[][];
  profiles: Profile[][];
}

export interface Customer {
  userId: string;
  status: 'active' | 'blocked';
}

export interface EnrichedOrdersOverview {
  shopId: string;
  orders: number;
  items: number;
  users: number;
  hasUsedWidget: number;
  followedAdvice: number;
  buttonShown: number;
  returned: number;
  sizeRelated: number;
  fasletReturns: number;
  followedAdviceReturns: number;
  fasletSRReturns: number;
  followedAdviceSRReturns: number;
}

export interface ApplicationData {
  loading: boolean;
  users: User[];
  items: Item[];
  sourceItems: Item[];
  retailers: Retailer[];
  retailersSearch: Retailer[];
  customers: { [retailer: string]: Customer[] };
  brands: Brand[];
  aliases: BrandAlias[];
  untaggedProducts: UntaggedProduct[];
  untaggedProductSummary: UntaggedProductSummary[];
  integrationDetails: IntegrationDetails;
  billingDetails: BillingDetails;
  types: Type[];
  products: Product[];
  tags: Tag[];
  measurements: Measurement[];
  webhooks: Webhook[];
  experiments: Experiment[];
  extendedUser: ExtendedUser;
  extendedRetailer: ExtendedRetailer;
  extendedBrand: ExtendedBrand;
  extendedProduct: ExtendedProduct;
  extendedTag: ExtendedTag;
  sizeUploads: SizeChartsUploadInfo[];
  slackValues: SlackValue[];
  logoUploadUrl: string | null;
  currentUser: {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    role: string;
    avatar?: string | null;
    avatarS3?: string | null;
  };
  pricePlan: {
    price?: number;
    discount?: number;
    name?: string;
    planId?: string;
  };
  profile: {
    firstName: string;
    lastName: string;
    email: string;
    role: string;
    id: number;
    retailers: { name: string; logo: string; id: string }[];
    avatar?: string | null;
  };
  notifications: Notification[];

  currentBilling: {
    taxNumber: string;
    companyName: string;
  };
  analytics: {
    productStats: {
      products: {
        productId: string;
        ordered: number;
        returned: number;
        sizeRelated: number;
        product: string;
        productType: string;
        productTypeRoot: string;
      }[];
    };
    profileStats: {
      ages: {
        gender: string;
        age: number;
        count: number;
        orders: number;
        returns: number;
        sizeRelatedReturns: number;
      }[];
      heights: {
        gender: string;
        height: number;
        count: number;
        orders: number;
        returns: number;
        sizeRelatedReturns: number;
      }[];
      weights: {
        gender: string;
        weight: number;
        count: number;
        orders: number;
        returns: number;
        sizeRelatedReturns: number;
      }[];
      genders: {
        gender: string;
        count: number;
        orders: number;
        returns: number;
        sizeRelatedReturns: number;
      }[];
      referenceShoeBrands: {
        gender: string;
        referenceShoeBrand: string;
        count: number;
        orders: number;
        returns: number;
        sizeRelatedReturns: number;
      }[];
      fits: {
        gender: string;
        fit: number;
        count: number;
        orders: number;
        returns: number;
        sizeRelatedReturns: number;
      }[];
    };
    globalStats: {
      events: { eventName: string; users: number; events: number }[];
      orderUplift: { hasUsedWidget: boolean; orders: number; users: number }[];
      aovUplift: {
        hasUsedWidget: boolean;
        aov: number;
      }[];
    };
    globalStatus: {
      [retailerName: string]: {
        widget_show: number;
        widget_order_track: number;
      };
    };
    missedSalesSummary: {
      missedSales: MissedSale[];
      paginationToken?: string;
    };
    experimentStats: {
      users: number;
      events: number;
      eventName: string;
      experimentVariants: string;
    }[];
    roiSummary: {
      value: number;
      fasletArpu: number;
      nonFasletArpu: number;
      pdf: string;
    };
    realtime: {
      realtime: {
        fasletUsers: number;
        pdpUsers: number;
        sizeRecommendations: number;
        orderUsers: number;
      };
      realtimePerStore: {
        fasletUsers: number;
        pdpUsers: number;
        sizeRecommendations: number;
        orderUsers: number;
        shopId: string;
      }[];
      errors: {
        message: string;
      }[];
    };
    enrichedOrdersOverview: EnrichedOrdersOverview[];
  };
  tagMetadata: TagMetadata[];
  multipliers: Multiplier[];
  productPredictor: {
    dictionary: PredictorDictionary;
    ignoreList: { words: string[] };
  };
  returns: {
    productReturns: { productReturns: ProductReturn[]; count: number };
    repeatReturners: { repeatReturners: RepeatReturner[]; count: number };
  };
  productAccuracy: {
    summary: GroupedAccuracyStat[];
    items: {
      [k: string]: AccuracyStat[];
    };
  };
  widgetErrors: { errors: WidgetErrorGroup[] };
  errorProfiles: {
    errors: ErrorProfile[];
    count: number;
  };
  productInfo: {
    [shopId: string]: {
      [productId: string]: ProductInfoResponse;
    };
  };
  productInfos: {
    products: ProductInfoResponse[];
    count: number;
  };
  globalConfig: {
    referenceBrands: ReferenceBrand[];
    referenceModels: ReferenceModel[];
  };
  auditTrail: {
    user: {
      [userId: string]: AuditTrailEvent[];
    };
    action: {
      [action: string]: AuditTrailEvent[];
    };
    search: AuditTrailEvent[];
  };
  stepConfigs: {
    retailer: {
      [retailer: string]: StepConfig[];
    };
    global: StepConfig[];
  };
  categories: Category[];
  coupons: { issued: Coupon[]; redeemed: RedeemedCoupon[] };
}

export const SpotContext = createContext<{
  spot: Spot<ApplicationData>;
}>({
  spot: undefined!
});

export const useSpot = () => {
  const { spot } = useContext(SpotContext);
  const [data, setData] = useState<ApplicationData>({ ...spot.data });
  const [loading, setLoading] = useState(spot.data.loading);
  const [errors, setErrors] = useState<SpotError[]>([...spot.errors]);

  function buildUrl(endpoint: string, params = {}) {
    const queryString = params
      ? Object.entries(params).reduce(
          (accum, current) =>
            !!current[1]
              ? `${
                  accum + (accum.length === 0 ? '?' : '&')
                }${current[0]}=${current[1]}`
              : accum,
          ''
        )
      : '';
    return `${endpoint}${queryString}`;
  }

  useEffect(() => {
    // Sub to loading changes
    return spot.store.subscribe(() => {
      setLoading(spot.data.loading);
    });
  });

  const query = useCallback(
    async (
      endpoint: string,
      params: Record<string, unknown>,
      storagePath: string[],
      config?: { method: string | undefined }
    ) => {
      const oldErrors = [...spot.errors];

      await spot.query(endpoint, params ?? {}, storagePath, {
        ...config,
        credentials: 'include'
      });
      setData({
        ...spot.data
      });

      const newErrors = spot.errors.slice(oldErrors.length);
      setErrors([...spot.errors]);

      if (newErrors.find(err => err.status === 401)) {
        redirectToLogin();
      }

      if (newErrors.length) {
        // eslint-disable-next-line no-throw-literal
        throw [...newErrors];
      }
    },
    [spot, setData, setErrors]
  );

  const command = useCallback(
    async (
      endpoint: string,
      params?: Record<string, unknown>,
      config?: { method: string | undefined }
    ) => {
      const oldErrors = [...spot.errors];

      await spot.command(endpoint, params || {}, {
        ...config,
        credentials: 'include'
      });

      const newErrors = spot.errors.slice(oldErrors.length);
      setErrors([...spot.errors]);

      if (newErrors.find(err => err.status === 401)) {
        redirectToLogin();
      }

      if (newErrors.length) {
        // eslint-disable-next-line no-throw-literal
        throw [...newErrors];
      }
    },
    [spot, setErrors]
  );

  const raw = useCallback(
    async <T = any>(
      endpoint: string,
      config?: RequestInit,
      credentials: 'omit' | 'include' | 'same-origin' = 'include'
    ) => {
      setLoading(true);
      const fetchConfig = config ? { ...config } : {};

      fetchConfig.headers = {
        ...config?.headers
      };

      let response: Response | null = null;

      try {
        response = await fetch(`${getApi()}/${endpoint}`, {
          ...fetchConfig,
          credentials
        });
      } catch (e) {
        console.error(e);
        throw [
          {
            message: `Error fetching the endpoint ${endpoint}`,
            status: 500,
            statusText: 'Internal Server Error',
            body: e
          }
        ];
      }

      setLoading(false);

      if (response?.status === 204) {
        return undefined;
      }

      if (response?.ok) {
        if (!response.body) {
          return undefined;
        }

        const contentType = response.headers.get('Content-Type') || '';

        if (contentType) {
          if (contentType.indexOf('text/csv') !== -1) {
            return response.text() as Promise<T>;
          }

          if (contentType.indexOf('application/pdf') !== -1) {
            return response.blob() as Promise<T>;
          }

          if (contentType.indexOf('application/json') !== -1) {
            return response.json() as Promise<T>;
          }

          return response.text() as Promise<T>;
        }

        return undefined;
      }

      if (response?.status === 401) {
        redirectToLogin();
      }

      const error = {
        message: `Error fetching the endpoint ${endpoint}`,
        status: response?.status,
        statusText: response?.statusText,
        body: await response?.text()
      } as SpotError;

      setErrors([...spot.errors, error]);

      // eslint-disable-next-line no-throw-literal
      throw [error];
    },
    [spot, setErrors]
  );

  const rawQuery = useCallback(
    async <T>(
      endpoint: string,
      params: Record<string, unknown>,
      config?: { method: string | undefined }
    ) => {
      const url = buildUrl(endpoint, params);
      return raw<T>(url, {
        method: 'GET',
        ...config
      }) as T;
    },
    [raw]
  );

  const rawCommand = useCallback(
    async (
      endpoint: string,
      params: Record<string, unknown>,
      config?: { method: string | undefined }
    ) => {
      return raw(endpoint, {
        method: 'POST',
        body: JSON.stringify(params),
        headers: {
          'Content-Type': 'application/json'
        },
        ...config
      });
    },
    [raw]
  );

  const isAdmin = useCallback(() => {
    return spot.data.profile?.role === 'admin';
  }, [spot]);

  return {
    spot,
    data,
    query,
    command,
    raw,
    rawQuery,
    rawCommand,
    loading,
    allErrors: errors,
    isAdmin
  };
};
